<template>
  <div class="filter-list-project">
    <div class="filter-list-project__body">
      <div class="filter-list-project__search-additional-col">
        <div class="w-100">
          <p class="label-field">Выберите отрасли</p>
          <SelectMultiComponentCheckbox
            placeholder="Выберите отрасль"
            v-model="filter.industryIds"
            :options="industries"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
          />
        </div>

        <div class="w-100">
          <p class="label-field">Название</p>
          <ComponentInput v-model="filter.name" placeholder="Введите название" mod="rectangular" />
        </div>

        <div class="w-100">
          <p class="label-field">Участники Российской стороны</p>
          <SelectMultiComponentCheckbox
            placeholder="Выберите участников"
            v-model="filter.participantsDomesticIds"
            :options="participantsDomestic"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
          />
        </div>

        <div class="w-100">
          <p class="label-field">Ключевые слова</p>
          <ComponentInput v-model="filter.description" placeholder="Введите слова" mod="rectangular" />
        </div>

        <div class="w-100">
          <p class="label-field">Срок начала реализации</p>
          <div class="filter-list-project__row">
            <div class="filter-list-project__period">
              <span class="label-field">От</span>
              <date-component v-model="filter.startMonthYearFrom" :clearable="false" modClass="redesigned" dateIcon />
            </div>

            <div class="filter-list-project__period">
              <span class="label-field">До</span>
              <date-component v-model="filter.startMonthYearTo" :clearable="false" modClass="redesigned" dateIcon />
            </div>
          </div>
        </div>
        <div class="w-100">
          <p class="label-field">Объемы инвестиций</p>
          <div class="filter-list-project__row">
            <div class="filter-list-project__period">
              <span class="label-field">От</span>

              <ComponentInput v-model="filter.investmentsAmountFrom" mod="rectangular" type="number" />
            </div>

            <div class="filter-list-project__period">
              <span class="label-field">До</span>

              <ComponentInput v-model="filter.investmentsAmountTo" mod="rectangular" type="number" />
            </div>
          </div>
        </div>
      </div>

      <div class="filter-list-project__search-additional-col">
        <div class="w-100">
          <p class="label-field">Цели</p>
          <SelectMultiComponentCheckbox
            placeholder="Выберите цели"
            v-model="filter.purposeIds"
            :options="purposes"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
          />
        </div>

        <div class="w-100">
          <p class="label-field">Статусы</p>
          <SelectMultiComponentCheckbox
            placeholder="Выберите статусы"
            v-model="filter.statusIds"
            :options="statuses"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
          />
        </div>

        <div class="w-100">
          <p class="label-field">Участник Иностранной стороны</p>
          <ComponentInput v-model="filter.participantsForeign" placeholder="Введите участника" mod="rectangular" />
        </div>

        <div class="w-100">
          <p class="label-field">Перечень проекта</p>
          <SelectSingleComponentCheckbox
            placeholder="Выберите участников"
            v-model="projectListId"
            :options="mpkListProjects"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
          />
        </div>

        <div class="w-100">
          <p class="label-field">Срок конца реализации</p>
          <div class="filter-list-project__row">
            <div class="filter-list-project__period">
              <span class="label-field">От</span>
              <date-component v-model="filter.endMonthYearFrom" :clearable="false" modClass="redesigned" dateIcon />
            </div>

            <div class="filter-list-project__period">
              <span class="label-field">До</span>
              <date-component v-model="filter.endMonthYearTo" :clearable="false" modClass="redesigned" dateIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-list-project__footer">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import dateComponent from '@/common/components/dateComponent.vue';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import Utils from '@/common/utils';

  import API_ORG from '@/modules/admin/api/organization';
  import { DEFAULT_FILTER_VALUES } from '@/modules/foreignMarket/utils/consts';
  import API_NSI from '@/modules/nsi/api/nsi';

  import { MPK_PROJECT_ACTIONS_NAME, MPK_PROJECT_GETTERS_NAME } from '../store/name';

  export default {
    name: 'FilterListProject',
    components: { ComponentInput, dateComponent, SelectMultiComponentCheckbox, SelectSingleComponentCheckbox },
    props: {
      filterProps: {
        type: Object,
        required: true,
        default: () => DEFAULT_FILTER_VALUES,
      },
    },
    data() {
      return {
        filter: this.filterProps,

        statuses: [],
        industries: [],
        purposes: [],
        participantsDomestic: [],
      };
    },
    mounted() {
      this.loadDictionaries();
      this.loadOtherDictionaries();
    },
    computed: {
      ...mapGetters({ mpkListProjects: MPK_PROJECT_GETTERS_NAME.getMpkListProjects }),
      projectListId: {
        get() {
          return this.filter.projectListId;
        },
        set(val) {
          // потому что при очищении селекта вручную присваивается значение 'null'
          this.filter.projectListId = val === 'null' ? null : Number(val);
        },
      },
    },
    methods: {
      ...mapActions({ getMpkProjectList: MPK_PROJECT_ACTIONS_NAME.getMpkProjectList }),

      loadDictionaries() {
        let request = { pageNumber: 1, pageSize: 0 };
        Utils.loadSelectOptions('mpkProjectStatusList', this.statuses);
        API_NSI.search('NsiIndustry', request).then((response) => {
          if (response.data) {
            this.industries = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
        API_NSI.search('MpkProjectPurpose', request).then((response) => {
          if (response.data) {
            this.purposes = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
        API_ORG.search(request).then((response) => {
          if (response.data) {
            this.participantsDomestic = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
      },
      async loadOtherDictionaries() {
        await this.getMpkProjectList();
      },
    },
  };
</script>

<style src="./../styles/filter-list-project.scss" lang="scss" />
