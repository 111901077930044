<template>
  <div class="container-page big">
    <div class="list-projects">
      <div class="list-projects__header">
        <h2 class="list-projects__title title-inner">Перечень проектов</h2>
        <div class="list-projects__nav-button">
          <ButtonStock title="Карта проектов" @click="onProjectMapClick" />
        </div>
        <div class="list-projects__nav-col">
          <ButtonStock title="Создать" @click="editCard(0)" />
          <ButtonStock
            title="Экспорт списка"
            @click="onDownloadBtn"
            :disabled="exportInProgress"
            btnType="grayBlueNoBorder"
          />
        </div>
      </div>

      <div class="list-projects__search-visible">
        <div class="w-100">
          <p class="label-field">Страна</p>
          <SelectMultiComponentCheckbox
            placeholder="Все"
            v-model="filter.countryIds"
            :options="computedCountryList"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
          />
        </div>
      </div>

      <div
        class="filter-trigger"
        :class="{ active: openAdditional, 'filter-have-value': filterNotEmpty }"
        @click="openAdditional = !openAdditional"
      >
        <p class="filter-trigger__title">Фильтр</p>
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17 1H1L7.4 8.35778V13.4444L10.6 15V8.35778L17 1Z"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div v-if="openAdditional" class="list-projects__search-additional">
        <FilterListProject :filter-props="filter">
          <ButtonStock title="Применить" class="btn--average" @click="changeFilter()" />
          <ButtonStock title="Сбросить" btnType="blueColorNoBorder" @click="resetFilter()" />
          <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
        </FilterListProject>
      </div>

      <div class="list-projects__body">
        <TableListProject
          :tableInfo="page"
          :canEditProject="canEditProject"
          :isLoading="isLoading"
          @editCard="editCard"
          @deleteCard="deleteCard"
          @viewCard="viewCard"
        />
      </div>

      <div v-if="page.pagesTotal > 1">
        <PaginationRedesigned
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>

    <ModalComponent title="Скачать" @close="downloadDialog = false" v-model="downloadDialog">
      <div>
        <div class="mb-32">
          <div class="mb-4 required">
            <ComponentInput
              v-model="selectedFileFormat"
              type="select2"
              :select2-options="fileFormats"
              :select2-settings="select2SettingsNoClear"
              label="Выберите формат файла"
              required
            />
          </div>
        </div>
        <ButtonStock @click="downloadFile" title="Скачать" :disabled="exportInProgress" />
      </div>
    </ModalComponent>
    <section>
      <ModalComponent v-model="importModal" title="Импорт файла" @close="closeModal">
        <div>
          <label href="#" class="label-href float-right mb-2" @click="getUploadTemplate">Скачать шаблон</label>
        </div>
        <ImportModal @doimport="doImport" @close="closeModal" />
      </ModalComponent>
    </section>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import ComponentInput from '@/common/components/ComponentInput';
  import DefaultFilter from '@/common/components/DefaultFilter';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned.vue';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import pagemanager from '@/common/mixins/pagemanager';
  import security from '@/common/mixins/security';
  import API_COUNTRY from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ImportModal from '@/components/modals/ImportModal';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API from '../api/index';
  import FilterListProject from '../components/FilterListProject.vue';
  import TableListProject from '../components/TableListProject.vue';
  import LocalConstants from '../constants';
  import { MPK_PROJECT_ACTIONS_NAME, MPK_PROJECT_GETTERS_NAME } from '../store/name';

  export default {
    name: 'Projects',
    components: {
      FilterListProject,
      TableListProject,
      PaginationRedesigned,
      SelectMultiComponentCheckbox,
      ButtonStock,
      ComponentInput,
      ModalComponent,
      DefaultFilter,
      ImportModal,
    },
    mixins: [security, filtermanager, pagemanager],
    props: {
      countryId: {
        type: Array,
        default: null,
      },
      industryId: {
        type: Array,
        default: null,
      },
      statusId: {
        type: Array,
        default: null,
      },
      purposeId: {
        type: Array,
        default: null,
      },
    },
    data() {
      return {
        page: {
          items: null,
          pageNumber: null,
          pagesTotal: null,
        },
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          countryIds: this.$route.params.countryId,
          name: '',
          purposeIds: this.$route.params.purposeId,
          statusIds: this.$route.params.statusId,
          industryIds: this.$route.params.industryId,
          participantsDomesticIds: [],
          projectListId: null,
        },
        filterNotEmpty: false,

        countries: [
          //{id: 0, text: "Выберите страну", selected: true},
        ],
        select2SettingsNoClear: {
          language: Constants.langRu,
          allowClear: false,
        },

        openAdditional: false,
        canEditProject: false,
        canCreateProject: false,
        fileFormats: LocalConstants.FileFormats,
        downloadDialog: false,
        selectedFileFormat: 1,
        exportInProgress: false,

        importModal: false,
        importInProgress: false,
        pageNumberKey: 'Projects_pageNumber',
        isLoading: false,
      };
    },
    watch: {
      countryIds: function (newValue) {
        if (newValue) this.filter.countryIds = newValue;
        this.changeFilter();
      },
      'filter.countryIds'() {
        this.changeFilter();
        this.$router.replace({
          query: this.filter.countryIds ? { countryId: this.filter.countryIds } : null,
        });
      },
    },
    mounted() {
      this.checkPermission(Constants.Permissions.MpkProjectsAccess).then((res) => {
        if (!res.data) {
          Constants.alert.fire('Нет доступа', 'У Вас нет доступа к просмотру матрицы проектов.', 'error');
          this.$router.push('/');
        } else {
          this.loadFilter().then((response) => {
            let filter = response;
            if (filter) {
              this.filter = filter;
            }
            //теперь надо установить то, что пришло в query
            if (this.countryId) {
              this.filter.countryIds = this.countryId;
            } else if (this.$route.params.countryId) {
              this.filter.countryIds = Array.isArray(this.$route.params.countryId)
                ? this.$route.params.countryId
                : [this.$route.params.countryId];
            } else if (this.$route.query.countryId) {
              this.filter.countryIds = Array.isArray(this.$route.query.countryId)
                ? this.$route.query.countryId
                : [this.$route.query.countryId];
            }

            if (this.purposeId) {
              this.filter.purposeIds = this.purposeId;
            } else if (this.$route.params.purposeId) {
              this.filter.purposeIds = Array.isArray(this.$route.params.purposeId)
                ? this.$route.params.purposeId
                : [this.$route.params.purposeId];
            } else if (this.$route.query.purposeId) {
              this.filter.purposeIds = Array.isArray(this.$route.query.purposeId)
                ? this.$route.query.purposeId
                : [this.$route.query.purposeId];
            }

            if (this.industryId) {
              this.filter.industryIds = this.industryId;
            } else if (this.$route.params.industryId) {
              this.filter.industryIds = Array.isArray(this.$route.params.industryId)
                ? this.$route.params.industryId
                : [this.$route.params.industryId];
            } else if (this.$route.query.industryId) {
              this.filter.industryIds = Array.isArray(this.$route.query.industryId)
                ? this.$route.query.industryId
                : [this.$route.query.industryId];
            }

            if (this.statusId) {
              this.filter.statusIds = this.statusId;
            } else if (this.$route.params.statusId) {
              this.filter.statusIds = Array.isArray(this.$route.params.statusId)
                ? this.$route.params.statusId
                : [this.$route.params.statusId];
            } else if (this.$route.query.statusId) {
              this.filter.statusIds = Array.isArray(this.$route.query.statusId)
                ? this.$route.query.statusId
                : [this.$route.query.statusId];
            }

            if (
              filter &&
              (!this.filter.countryIds || !this.filter.countryIds.length) &&
              (!this.filter.purposeIds || !this.filter.purposeIds.length) &&
              (!this.filter.industryIds || !this.filter.industryIds.length) &&
              (!this.filter.statusIds || !this.filter.statusIds.length)
            ) {
              this.filter = filter;
            }

            this.filterNotEmpty = this.isFilterNotEmpty(this.filter, ['countryIds']);
            this.request.pageNumber = this.loadPageNumber(this.pageNumberKey);
            this.loadPage();
          });
        }
      });
      this.checkPermission(Constants.Permissions.MpkProjectEdit).then((response) => {
        this.canEditProject = response.data;
      });
      this.checkPermission(Constants.Permissions.MpkProjectFoivReview).then((response) => {
        this.canCreateProject = !response.data;
      });
      API_COUNTRY.loadSelectOptions('countryList', this.countries, true);
    },
    beforeUnmount() {
      this.page = {
        items: null,
        pageNumber: null,
        pagesTotal: null,
      };
    },
    methods: {
      ...mapActions({ getMpkProjectSearch: MPK_PROJECT_ACTIONS_NAME.getMpkProjectSearch }),
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
        this.savePageNumber(this.pageNumberKey, pageNumber);
      },
      async loadPage(addToExist) {
        this.isLoading = true;
        let params = Object.assign({}, this.filter, this.request);
        try {
          const res = await this.getMpkProjectSearch(params);
          if (res.success) {
            if (!addToExist) {
              this.page = {};
              this.$nextTick(() => {
                this.page = res.data;
              });
            } else if (res.data) {
              this.page.pageNumber = res.data.pageNumber;
              this.page.items = this.page.items.concat(this.searchMpkProjects);
            }
          }
        } finally {
          this.isLoading = false;
        }
      },
      editCard(id) {
        this.$router.push({ name: 'EditProjectCard', params: { id: id } });
      },
      viewCard(item) {
        if (item.canView) this.$router.push({ name: 'ProjectCard', params: { id: item.id } });
        else {
          Constants.alert.fire('Недостаточно прав', 'У вас недостаточно прав для просмотра данных проекта', 'error');
        }
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then(() => {
                Constants.alert.fire('Удаление проекта', Constants.commonSuccess, 'success');
                this.loadPage();
              })
              .catch(() => {
                Constants.alert.fire('Удаление проекта', Constants.commonError, 'error');
              });
          }
        });
      },
      resetFilter() {
        this.filter.countryIds = null;
        this.filter.name = null;
        this.filter.purposeIds = null;
        this.filter.statusIds = null;
        this.filter.industryIds = null;
        this.filter.participantsDomesticIds = [];
        this.filter.projectListId = null;
        this.filter.description = '';
        this.filter.startMonthYearFrom = null;
        this.filter.startMonthYearTo = null;
        this.filter.investmentsAmountFrom = null;
        this.filter.investmentsAmountTo = null;
        this.filter.endMonthYearFrom = null;
        this.filter.endMonthYearTo = null;
        this.filter.participantsForeign = '';
        this.changeFilter();
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.filterNotEmpty = this.isFilterNotEmpty(this.filter, ['countryIds']);
        this.loadPage(false);
      },
      onDownloadBtn() {
        this.selectedFileFormat = 1;
        this.downloadDialog = true;
      },
      downloadFile() {
        this.exportInProgress = true;
        const params = Object.assign({ formatId: this.selectedFileFormat }, this.filter);
        API.export(params)
          .then(() => {
            this.downloadDialog = false;
            this.exportInProgress = false;
          })
          .catch(() => {
            Constants.alert.fire('Скачивание файла', 'Не удалось сформировать отчет', 'error');
            this.downloadDialog = false;
            this.exportInProgress = false;
          });
      },
      // pickFile() {
      //   Constants.alert.fire('Загрузка файла', "Файл успешно загружен", 'success');
      // },
      onProjectMapClick() {
        this.$router.push({ name: 'ProjectsMap' });
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
      closeModal() {
        this.importModal = false;
      },
      getUploadTemplate() {
        this.importInProgress = true;
        API.getUploadTemplate()
          .then(() => {
            this.importInProgress = false;
          })
          .catch(() => {
            Constants.alert.fire('Скачивание файла', 'Не удалось скачать шаблон', 'error');
            this.importInProgress = false;
          });
      },
      doImport(file) {
        this.closeModal();
        //this.importInProgress = true;
        //Constants.alert.fire('Загрузка файла', "Файл успешно загружен " + file, 'success');
        this.importInProgress = false;
        API.uploadFile(file)
          .then(() => {
            Constants.alert.fire('Импорт', 'Импорт успешно выполнен.', 'success');
            this.loadPage();
            this.importInProgress = false;
          })
          .catch((error) => {
            var txt = 'Ошибка при импорте данных.';
            if (error?.response?.data && error?.response?.data.startsWith('Ошибки при разборе файла'))
              txt = error?.response?.data.replaceAll('\n', '<br/>\n');
            Constants.alert.fire('Импорт', txt, 'error');
            this.importInProgress = false;
          });
      },
    },
    computed: {
      ...mapGetters({ searchMpkProjects: MPK_PROJECT_GETTERS_NAME.getSearchMpkProjects }),
      // для правки отображения выбранного значения в `@vueform/multiselect`
      computedCountryList() {
        return [...this.countries];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table-container table.stockTable thead th.filter {
    padding: 6px 4px;
    &:nth-of-type(9) {
      .select2-search__field {
        width: 88%;
      }
    }
  }

  .filter-trigger {
    display: inline-flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;

    &__title {
      font: $fira-16-B;
      font-size: 15px;
      text-transform: uppercase;
    }

    & svg {
      stroke: currentColor;
      width: 16px;
      height: 14px;
    }

    &.filter-have-value {
      color: red;
    }

    &.active {
      color: $green;
    }
  }
</style>

<style lang="scss" src="./../styles/list-projects.scss" />
