export default {
  methods: {
    savePageNumber(key, pageNum) {
      const realKey = this.getKey(key);
      if (realKey) localStorage.setItem(realKey, pageNum.toString());
    },
    loadPageNumber(key) {
      const realKey = this.getKey(key);
      if (realKey) {
        const pageNum = localStorage.getItem(realKey);
        return pageNum ? parseInt(pageNum) : 1;
      }
      return 1;
    },
    getKey(key) {
      const upd = localStorage.getItem('userProfileData');
      if (upd) {
        const profile = JSON.parse(upd);
        return key + '_' + profile.userName;
      }
      return null;
    },
  },
};
