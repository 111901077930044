<template>
  <div v-if="isLoading" class="loader">
    <Loader></Loader>
  </div>

  <div v-else class="list-projects__results-table table-container-new arrow-nav">
    <table class="stockTable table-list-projects">
      <thead>
        <tr>
          <th class="table-list-projects__td table-list-projects__td_size-xs mpk-first-column-fixed"></th>
          <th class="table-list-projects__td table-list-projects__td_size-sm mpk-second-column-fixed">
            <p>Статус</p>
          </th>
          <th class="table-list-projects__td table-list-projects__td_size-l mpk-third-column-fixed">
            <p>Название</p>
          </th>
          <th class="table-list-projects__td table-list-projects__td_size-l">
            <p>Страны реализации <br />инвестиционного проекта</p>
          </th>
          <th class="table-list-projects__td table-list-projects__td_size-l">
            <p>Страна-партнёр</p>
          </th>
          <th class="table-list-projects__td table-list-projects__td_size-m">
            <p>Плановый год начала реализации <br />инвестиционной стадии проекта</p>
          </th>
          <th class="table-list-projects__td table-list-projects__td_size-m">
            <p>Плановый год окончания реализации <br />инвестиционной стадии проекта</p>
          </th>
          <th class="table-list-projects__td table-list-projects__td_size-l">
            <p>Общий объем инвестиций, <br />млрд руб.</p>
          </th>
          <th class="table-list-projects__td table-list-projects__td_size-m"><p>Отрасль</p></th>
          <th class="table-list-projects__td table-list-projects__td_size-l"><p>Описание</p></th>
          <th class="table-list-projects__td table-list-projects__td_size-m"><p>Перечень проекта</p></th>
          <th class="table-list-projects__td table-list-projects__td_size-m"><p>Дата создания</p></th>
          <th class="table-list-projects__td table-list-projects__td_size-m"><p>Дата изменения</p></th>
        </tr>
      </thead>
      <tbody v-if="tableInfo.items && tableInfo.items.length">
        <tr
          v-for="item in tableInfo.items"
          :key="item.id"
          @dblclick="viewCard(item)"
          class="cursorPointer hover-tr"
          :class="{ 'back-grey': item?.toArchive }"
        >
          <td class="table-list-projects__td table-list-projects__td_size-xs mpk-first-column-fixed">
            <div class="table-list-projects__td-icons-wrap">
              <svg
                v-show="canEditProject && item.canEdit && !item.fromEhd"
                @click.stop="editCard(item.id)"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                class="editIcon"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clip-path="url(#clip0_5804_11650)">
                  <path
                    d="M7.33301 2.66663H2.66634C2.31272 2.66663 1.97358 2.8071 1.72353 3.05715C1.47348 3.3072 1.33301 3.64634 1.33301 3.99996V13.3333C1.33301 13.6869 1.47348 14.0261 1.72353 14.2761C1.97358 14.5262 2.31272 14.6666 2.66634 14.6666H11.9997C12.3533 14.6666 12.6924 14.5262 12.9425 14.2761C13.1925 14.0261 13.333 13.6869 13.333 13.3333V8.66663"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.333 1.66665C12.5982 1.40144 12.9579 1.25244 13.333 1.25244C13.7081 1.25244 14.0678 1.40144 14.333 1.66665C14.5982 1.93187 14.7472 2.29158 14.7472 2.66665C14.7472 3.04173 14.5982 3.40144 14.333 3.66665L7.99967 9.99999L5.33301 10.6667L5.99967 7.99999L12.333 1.66665Z"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5804_11650">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <svg
                v-show="canEditProject && item.canEdit"
                class="removeIcon"
                @click.stop="deleteCard(item.id)"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path d="M2 4H3.33333H14" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M12.6663 4.00004V13.3334C12.6663 13.687 12.5259 14.0261 12.2758 14.2762C12.0258 14.5262 11.6866 14.6667 11.333 14.6667H4.66634C4.31272 14.6667 3.97358 14.5262 3.72353 14.2762C3.47348 14.0261 3.33301 13.687 3.33301 13.3334V4.00004M5.33301 4.00004V2.66671C5.33301 2.31309 5.47348 1.97395 5.72353 1.7239C5.97358 1.47385 6.31272 1.33337 6.66634 1.33337H9.33301C9.68663 1.33337 10.0258 1.47385 10.2758 1.7239C10.5259 1.97395 10.6663 2.31309 10.6663 2.66671V4.00004"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M6.66699 7.33337V11.3334" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.33301 7.33337V11.3334" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <Tooltip v-if="item?.toArchive" :triggers="['hover', 'click']">
                <p class="archive-tooltip-text">Планируется отправка в архив</p>
              </Tooltip>
            </div>
          </td>
          <td
            class="table-list-projects__td table-list-projects__td_size-sm table-list-projects__td_status-wrap mpk-second-column-fixed"
          >
            <Tooltip :triggers="['hover', 'click']" class="ml-30">
              <slot name="tooltipContent">
                <TooltipContentTableProject :statusId="item.status.id" />
              </slot>
              <template v-slot:triggerContent>
                <span :class="getClassStatus(item.status.id)">{{ item.status.name }}</span>
              </template>
            </Tooltip>
          </td>
          <td class="table-list-projects__td table-list-projects__td_size-l mpk-third-column-fixed">
            {{ item.name }}
          </td>
          <td class="table-list-projects__td table-list-projects__td_size-l">
            <ul>
              <li v-for="countryName in getProjectCountriesNames(item)" :key="countryName">
                {{ countryName }}
              </li>
            </ul>
          </td>
          <td class="table-list-projects__td table-list-projects__td_size-l">{{ item.partnerCountry?.name }}</td>
          <td class="table-list-projects__td table-list-projects__td_size-m">
            {{ item.plannedYearStartInvStage }}
          </td>
          <td class="table-list-projects__td table-list-projects__td_size-m">
            {{ item.plannedYearEndInvStage }}
          </td>
          <td class="table-list-projects__td table-list-projects__td_size-l">
            {{ item.totalInvestmentMlrdRub }}
          </td>
          <td class="table-list-projects__td table-list-projects__td_size-m">{{ item.industry.name }}</td>
          <td class="table-list-projects__td table-list-projects__td_size-l">
            <p v-snip="3">{{ item.description }}</p>
          </td>
          <td class="table-list-projects__td table-list-projects__td_size-m">{{ item.projectList?.name }}</td>
          <td class="table-list-projects__td table-list-projects__td_size-m">
            {{ dateFormat(item.created, 'DD.MM.YYYY HH:mm') }}
          </td>
          <td class="table-list-projects__td table-list-projects__td_size-m">
            {{ dateFormat(item.modified, 'DD.MM.YYYY HH:mm') }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="13">Нет данных.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import Tooltip from '@/common/ui/Tooltip.vue';

  import Loader from '@/components/Loader';

  import TooltipContentTableProject from './TooltipContentTableProject.vue';

  export default {
    name: 'TableListProject',
    components: { Tooltip, TooltipContentTableProject, Loader },
    props: {
      tableInfo: {
        type: Object,
        default: () => {
          return {
            pageNumber: 1,
            pageSize: 10,
            items: [],
            itemsTotal: 0,
            pagesTotal: 1,
          };
        },
      },
      canEditProject: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      getClassStatus(id) {
        switch (id) {
          case 1:
            return 'green-dark';
          case 2:
            return 'green';
          case 3:
            return 'blue';
          default:
            return 'warning';
        }
      },
      viewCard(item) {
        this.$emit('view-card', item);
      },
      editCard(id) {
        this.$emit('edit-card', id);
      },
      deleteCard(id) {
        this.$emit('delete-card', id);
      },
      dateFormat(date, format) {
        return date ? this.$momentFormat(date, Constants.DATE_FORMATS[format]) : '';
      },
      getProjectCountriesNames(item) {
        return item?.projectCountries?.map?.((entry) => entry.country.name) ?? null;
      },
    },
  };
</script>

<style src="../styles/table-list-projects.scss" scoped lang="scss" />
