<template>
  <div class="tooltip-content-projects">
    <div v-if="statusId === projectStatusId.EXPLOITATION">
      <p>
        По успешному завершению инвестиционной стадии проект выходит в режим эксплуатации. Он начинается с момента ввода
        в эксплуатацию основного оборудования и включает в себя пуск в действие предприятия, выход его на проектную
        мощность, а также выпуск и сбыт продукции запланированного качества и количества.
      </p>
    </div>

    <div v-else-if="statusId === projectStatusId.INVEST">
      <p>
        По итогам успешного завершения прединвестиционной стадии, проект приступает к осуществлению, формированию
        постоянных активов, которое включает в себя:
      </p>
      <ui>
        <li>разработку проектно-сметной документации;</li>
        <li>строительство производственных зданий и сооружений;</li>
        <li>заказ и поставку технологического оборудования;</li>
        <li>его монтаж и пусконаладочные работы;</li>
        <li>найм и обучение персонала и другие затраты.</li>
      </ui>
    </div>

    <div v-else-if="statusId === projectStatusId.PRED_INVEST">
      <p>Подготовительный этап, который включает в себя:</p>
      <ui>
        <li>
          формирование инвестиционного замысла (генерацию идеи проекта), а также его инновационный и патентный анализ;
        </li>
        <li>технико-экономическое обоснование (ТЭО) инвестиционного проекта и составление его бизнес-плана;</li>
        <li>поиск потенциальных инвесторов и источников финансирования инвестиционного проекта;</li>
        <li>юридическое оформление инвестиционного проекта;</li>
        <li>заключение договоров с заказчиками (подрядчиками).</li>
      </ui>
    </div>

    <div v-else-if="statusId === projectStatusId.STOPPED">
      <p>
        Применяется в рамках нахождения проекта на одной из трех основных стадий (пред инвестиционной, инвестиционной
        или же эксплуатационной). Вводится при наличии информации, что в данный момент работа по проекту не
        осуществляется.
      </p>
    </div>
    <div v-else>
      <p>Нет информации</p>
    </div>
  </div>
</template>

<script>
  import constants from '../constants';
  export default {
    name: 'TooltipContentTableProject',
    props: {
      statusId: {
        type: Number,
      },
    },
    data: () => ({
      projectStatusId: constants.MPK_PROJECT_STATUS_ID,
    }),
  };
</script>

<style lang="scss" scoped>
  .tooltip-content-projects {
    p {
      margin-bottom: 10px;
      font: $fira-14;
    }
    ui > li {
      font: $fira-14;
    }
  }
</style>
